body, #root {
  margin: 0;
  height: 100vh;
  font-family: FranklinGothicBook;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'FranklinGothicBook';
  src: local('FranklinGothicBook'), url(./assets/fonts/FRABK.ttf) format('truetype');
}

@font-face {
  font-family: 'FranklinGothicDemi';
  src: local('FranklinGothicDemi'), url(./assets/fonts/FRADM.ttf) format('truetype');
}

@font-face {
  font-family: 'FranklinGothicHeavy';
  src: local('FranklinGothicHeavy'), url(./assets/fonts/FRAHV.ttf) format('truetype');
}

@font-face {
  font-family: 'FranklinGothicMedium';
  src: local('FranklinGothicMedium'), url(./assets/fonts/FRAMD.ttf) format('truetype');
}

@keyframes modal {
  from  {bottom: 0; opacity: 0;}
  to  {bottom: 75; opacity: 1;}
}